type Config = {
  url: string;
  apiKey: string;
  recommendationsApiUrl: string;
};

export const klevuConfig: Config = {
  url: 'https://eucs14v2.ksearchnet.com/cs/v2/search',
  apiKey: 'klevu-15662830849705871',
  recommendationsApiUrl: 'https://config-cdn.ksearchnet.com/recommendations/',
};
